const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/terms-and-conditions"],
    exact: true,
    component: "TermsAndConditions",
  },
  {
    path: ["/privacy-policy"],
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    path: ["/coins"],
    exact: true,
    component: "Coins",
  },
];

export default routes;
